import './App.scss'
import { Layout } from 'antd'
import clsx from 'clsx'
import WHHeader from 'components/WHHeader'
import WHFooter from 'components/WHFooter'
import Routes from './routes'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'


const { Content } = Layout

const App = () => {
  const history = useHistory()
  let key = history?.location?.pathname
  return (
    <Layout>
      <WHHeader />
      <Content className={clsx('main')}>
        <Routes />
      </Content>
      <WHFooter key={key}/>
    </Layout>
  )
}
export default withRouter(App)
