import styles from './index.module.scss'
import email from 'static/images/email.png'
import wechart from 'static/images/wechart.png'
// import wifi from 'static/images/wifi.png'
import footer_hsk from 'static/images/footer_hsk.png'
import footer_hk from 'static/images/footer_hk.png'
import footer_yct from 'static/images/footer_yct.png'
import wechert from 'static/images/wechert.png'
import { useHistory } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popover } from 'antd'
import { wohuiPolicy, TermsOfUse, emailAddress } from 'config'

const WHFooter = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const isHome = useMemo(() => {
    return history?.location?.pathname == '/home'
  }, [history])

  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }
  return (
    <div className={styles.styles}>
      <div className='link'>
        <div className='follow-us'>
          <p>{t('footer.follow_us')}</p>
          <div className='follow-item'>
            <img src={email} alt='email' onClick={() => window.open(emailAddress)} /> 
            <Popover
              content={<img src={wechert} className='popover-image' />}
              trigger='click'
              open={open}
              onOpenChange={handleOpenChange}
            >
              <img src={wechart} alt='wechart' />
            </Popover>

            {/* <img src={wifi} alt='wifi' onClick={() => window.open(wifiURl)} /> */}
          </div>
        </div>
        <div className='official'>
          <p>{t('footer.desc-1')}</p>
          <div className='official-item'>
            <img src={footer_yct} alt='footer_yct' />
            <img src={footer_hk} alt='footer_hk' />
            <img src={footer_hsk} alt='footer_hsk' />
          </div>
        </div>
        <div className='Policy'>
          <a target='_blank' href={TermsOfUse}>{t('footer.desc-2')}</a>
          <a target='_blank' href={wohuiPolicy}>{t('footer.desc-3')}</a>
          <p>{t('footer.desc-4')}</p>
          <p>{t('footer.desc-5')}</p>
        </div>
      </div>
    </div>
  )
}

export default WHFooter
