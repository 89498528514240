export const key = 'dulw1c7webs1te'

const _apiHost = {
  dev: 'https://staging-api.wohuimandarin.com',
  beta: 'https://staging-api.wohuimandarin.com',
  prod: 'https://api.wohuimandarin.com',
}

export const ApiHost = _apiHost[process.env.REACT_APP_MODE]

export const Explore = 'https://articles.wohuimandarin.com/'

// hsk 隐私协议
export const hskPolicy =
  'https://mock-public.wohui.co/static/mock_mobile_web/policy/china/en/privacy_policy.html'

// wohui隐私协议
export const wohuiPolicy = 'https://articles.wohuimandarin.com/privacy/'

// 购买笔隐私协议
export const byPenPolicy = 'https://articles.wohuimandarin.com/privacy/'

// 联系我们隐私协议
export const connectPolicy = 'https://articles.wohuimandarin.com/privacy/'

// wifi跳转链接

export const wifiURl = 'https://articles.wohuimandarin.com/explore/feed/'    //暂时不用

// 百度地图谷歌地址

export const baidu_shanghai =
  'https://map.baidu.com/search/%E6%81%92%E9%9A%86%E5%B9%BF%E5%9C%BA%E5%8A%9E%E5%85%AC%E6%A5%BC-2%E5%8F%B7%E6%A5%BC/@13520933.215,3640865.87,19z?querytype=s&da_src=shareurl&wd=%E6%81%92%E9%9A%86%E5%B9%BF%E5%9C%BA%E5%8A%9E%E5%85%AC%E6%A5%BC-2%E5%8F%B7%E6%A5%BC&c=289&src=0&wd2=%E4%B8%8A%E6%B5%B7%E5%B8%82%E9%9D%99%E5%AE%89%E5%8C%BA&pn=0&sug=1&l=12&b=(13461825.310000006,3609786.639999998;13584705.310000006,3672442.639999998)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=34e48508d8f8eae3d9d8918b&device_ratio=1'

// 百度地图新加披地址

export const baidu_singapore =
  'https://map.baidu.com/search/united%20square%20office%20tower/@11559949.995,145641.31,19z?querytype=s&da_src=shareurl&wd=United%20Square%20Office%20Tower&c=20001&src=0&wd2=%E6%96%B0%E5%8A%A0%E5%9D%A1&pn=0&sug=1&l=16&b=(11556118.57057311,142863.1294214414;11561473.982716687,145593.83175923402)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=93028bd1681fc6473804b111&device_ratio=1'

// 谷歌地图上海地址

export const google_shanghai =
  "https://www.google.com/maps/place/31%C2%B013'41.1%22N+121%C2%B027'11.0%22E/@31.2280799,121.4508722,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xb889b387175ac1d1!8m2!3d31.2280753!4d121.4530609"

// 谷歌地图新加披地址
export const google_singapore =
  "https://www.google.com/maps/place/1%C2%B019'02.3%22N+103%C2%B050'36.9%22E/@1.3173107,103.8414054,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x2caab610fbc62518!8m2!3d1.3173053!4d103.8435941"


export const TermsOfUse = "https://articles.wohuimandarin.com/terms/"

export const emailAddress = "https://wohuimandarin.us18.list-manage.com/subscribe?u=ebb71ed848680fe5b9e4caa78&id=a4fec2cd25"

export const IS_CHINA = process.env.REACT_APP_ENVIRONMENT?.includes('china')
