import styles from './index.module.scss'
import logo from 'static/images/Logo.png'
import { useHistory } from 'react-router-dom'
import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons'
import { Drawer } from '@material-ui/core'
// import { Drawer } from 'antd'

const WHHeader = () => {
  const [scrollDeviation, setScrollDeviation] = useState(0)
  const history = useHistory()
  // const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const handleScroll = (e) => {
    setScrollDeviation(document.documentElement.scrollTop || document.body.scrollTop)
  }

  const jumpHome = () => {
    history.push('/home')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const bgblack = useMemo(() => {
    const isHome = history?.location?.pathname == '/home'
    return (scrollDeviation > 70 && isHome) || !isHome
  }, [scrollDeviation, history?.location?.pathname ])

  const aboutUs = () => {
    // console.log(history)
    history.push('/about')
  }
  const connectUs = () => {
    history.push('/connect')
  }
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  // const MobileDrawer = () => {
  //   const [open, setOpen] = useState(false)
  //   const showDrawer = () => {
  //     setOpen(true)
  //   }
  //   const onClose = () => {
  //     setOpen(false)
  //   }
  //   return (
  //     <>
  //       <UnorderedListOutlined className='icon-style' onClick={showDrawer}/>
  //       <Drawer
  //         title='Basic Drawer'
  //         placement='right'
  //         closable={false}
  //         onClose={onClose}
  //         open={open}
  //         getContainer={false}
  //         style={{ position: 'absolute' }}
  //       >
  //         <p>Some contents...</p>
  //       </Drawer>
  //     </>
  //   )
  // }
  const MobileDrawer = () => {
    const [drawerVisible, setDrawerVisible] = useState(false)
    return (
      <>
        {drawerVisible ? (
          <CloseOutlined className='icon-style' onClick={() => setDrawerVisible(drawerVisible)} />
        ) : (
          <UnorderedListOutlined
            className='icon-style'
            onClick={() => setDrawerVisible(!drawerVisible)}
          />
        )}

        <Drawer
          className='mobile-drawer'
          anchor={'right'}
          open={drawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <div
            className='tab-list'
            onClick={() => {
              setDrawerVisible(false)
            }}
          >
            <div
              className='tab-item'
              onClick={() => {
                setTimeout(() => {
                  aboutUs()
                }, 500)
              }}
            >
              {t('header.about_us')}
            </div>
            <div
              className='tab-item'
              onClick={() => {
                setTimeout(() => {
                  connectUs()
                }, 500)
              }}
            >
              {t('header.connect')}
            </div>
            <div
              className='tab-item'
              onClick={() => {
                setTimeout(() => {
                  i18n.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')
                }, 500)
              }}
            >
              {i18n.language == 'en' ? '中文' : 'EN'}
            </div>
          </div>
        </Drawer>
      </>
    )
  }
  return (
    <div className={styles.styles}>
      <div className='header-main' style={{ backgroundColor: bgblack ? '#2c2f46' : 'transparent' }}>
        <img src={logo} className='' alt='logo' onClick={jumpHome} />
        {isMobile ? (
          <MobileDrawer></MobileDrawer>
        ) : (
          <div className='tools'>
            <div className='m-r-28 tab' onClick={aboutUs}>
              {t('header.about_us')}
            </div>
            <div className='m-r-28 tab' onClick={connectUs}>
              {t('header.connect')}
            </div>
            <div
              className='language tab'
              onClick={() => i18n.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')}
            >
              {i18n.language == 'en' ? '中文' : 'ENGLISH'}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WHHeader
